@mixin maxwidth($breakpoint) {
    @if $breakpoint==mobile {
        @media only screen and (min-width: 0px) and (max-width: 600px) {
            @content;
        }
    }

    @if $breakpoint==tablet {
        @media only screen and (min-width: 600px) and (max-width: 1024px) {
            @content;
        }
    }

    @if $breakpoint==desktop {
        @media only screen and (min-width: 1024px) {
            @content;
        }
    }
}