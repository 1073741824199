@import "./mediaquery";
@import "./variables";

.about {
  .hero {
    height: 85vh;
    width: 100%;
    background-image: linear-gradient(to right, $pinklighter, #efeeee);

    .container {
      max-width: 85%;
      width: 100%;
      height: 100%;
      margin: auto;
      gap: 2em;

      .left {
        height: fit-content;
        width: 100%;
        margin: auto;

        h1 {
          font-weight: 700;
          font-size: 44px;
          margin-bottom: 10px;
          color: #525ceb;
        }

        p {
          font-size: medium;
          color: $primarydark;
          margin-bottom: 2em;
          line-height: 1.7;
        }

        label {
          display: block;
          font-size: medium;
          color: $primarydark;
          margin-bottom: 10px;
        }

        input {
          padding: 8px;
          width: 70%;
          background-color: transparent;
          border-radius: 5px;
          border: 1px solid gray;
        }
      }

      .right {
        height: fit-content;
        max-width: 70%;
        width: 100%;
        margin: auto 0 auto auto;

        h3 {
          font-size: x-large;
          margin-bottom: 10px;
          text-align: right;
          color: $primarydark;
        }
      }

      img {
        max-width: 70%;
        width: 100%;
        display: block;
        object-fit: contain;
        margin: auto 0 auto auto;
      }

      button {
        background-color: $primary;
        padding: 8px 5em;
        color: $pinklighter;
        border: none;
        margin: 3em auto 0 auto;
        border-radius: 5px;
        cursor: pointer;
        display: block;
        transition: background-color 0.5s ease-in-out;
      }

      button:hover {
        background-color: $primarydark;
      }
    }

    @include maxwidth(mobile) {
      padding: 5em 0;
      height: fit-content;

      .container {

        .right {
          max-width: 100%;

          h3 {
            text-align: center;
          }
        }
      }
    }

    @include maxwidth(tablet) {
      padding: 5em 0;
      height: fit-content;

      .container {

        .right {
          max-width: 100%;

          h3 {
            text-align: center;
          }
        }
      }
    }
  }

  .how_works {
    padding: 4em 0;

    .container {
      max-width: 85%;
      width: 100%;
      margin: auto;
      gap: 2em;

      .item {
        background-color: $pinklighter;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        box-shadow: 2px 2px 5px #60606030;

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $pink;
          border-radius: 5px;
          z-index: -1;
          transition: transform 0.5s ease-in-out;
        }

        .holder {
          background-color: $pinklight;
          padding: 10px;
          border-radius: 5px 5px 0 0;
        }

        .content {
          padding: 10px;
        }

        .ic {
          width: 44px;
          height: 44px;
          border-radius: 44px;
          background-color: $primary;
          color: $pinklighter;
          margin: 10px auto;
          display: grid;
          place-content: center;
          font-size: x-large;
          font-weight: bolder;
        }

        h4 {
          color: $primary;
          margin-bottom: 10px;
          font-size: medium;
        }

        p {
          color: $primarydark;
          font-size: small;
        }
      }

      .item:hover {
        .bg {
          transform: translate(10px, 10px);
        }
      }
    }
  }

  .join_us {
    padding: 4em 0;
    background-color: $pinklighter;

    .container {
      max-width: 60%;
      width: 100%;
      margin: auto;

      p {
        text-align: center;
      }

      button {
        background-color: $primary;
        padding: 8px 5em;
        color: $pinklighter;
        border: none;
        margin: 3em auto 0 auto;
        border-radius: 5px;
        cursor: pointer;
        display: block;
        transition: background-color 0.5s ease-in-out;
      }

      button:hover {
        background-color: $primarydark;
      }
    }
  }

  .why_us {
    padding: 4em 0;

    .container {
      max-width: 85%;
      width: 100%;
      margin: auto;
      gap: 2em;

      .item {
        padding: 1em;
        background-color: $pinklighter;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        box-shadow: 2px 2px 5px #60606030;

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $pink;
          border-radius: 5px;
          z-index: -1;
          transition: transform 0.5s ease-in-out;
        }

        .ic {
          width: 44px;
          height: 44px;
          border-radius: 44px;
          background-color: $pink;
          margin: 10px auto;
        }

        h4 {
          color: $primary;
          margin-bottom: 10px;
          text-align: center;
          font-size: large;
        }

        p {
          color: $primarydark;
        }
      }

      .item:hover {
        .bg {
          transform: translate(10px, 10px);
        }
      }
    }
  }
}