@import "./mediaquery";
@import "./variables";

.home {

  .testimonials {
    padding: 4em 0;

    .container {
      max-width: 85%;
      width: 100%;
      margin: auto;

      gap: 2em;

      .item {
        display: grid;
        grid-template-columns: auto 1fr;

        .ic {
          width: 44px;
          height: 44px;
          border-radius: 44px;
          background-color: $pink;
          margin: 10px auto;
          padding: 10px;
          z-index: 99;
          display: grid;
          place-content: center;

          img {
            width: 32px;
            height: 32px;
            object-fit: contain;
            display: block;
            margin: auto;
          }
        }

        .wrapper {
          padding: 1em;
          background-color: $pinklighter;
          border-radius: 5px;
          cursor: pointer;
          position: relative;
          margin-top: 22px;
          margin-left: -22px;
          box-shadow: 2px 2px 5px #60606030;

          .bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $pink;
            border-radius: 5px;
            z-index: -1;
            transition: transform 0.5s ease-in-out;
          }

          h4 {
            color: $primary;
            margin-bottom: 10px;
            text-align: center;
            font-size: large;
          }

          p {
            color: $primarydark;
          }
        }
      }

      .item:hover {
        .bg {
          transform: translate(10px, 10px);
        }
      }
    }
  }

  .mission {
    padding: 4em 0;
    background-color: $pinklighter;

    .container {
      max-width: 60%;
      width: 100%;
      margin: auto;

      p {
        text-align: center;
      }
    }
  }
}