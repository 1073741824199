@import "./Styles/mediaquery";
@import "./Styles/variables";
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

html,
body {
  height: 100vh;
  width: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header {
  background-color: $primary;
  padding: 10px 0;
  top: 0;
  left: 0;
  width: 100%;
  transition: position 0.5s ease-in-out;
  z-index: 9999;

  .container {
    max-width: 85%;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    gap: 1em;

    img {
      max-height: 32px;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
      display: block;
      margin: auto;
    }

    h1 {
      color: $pinklighter;
      margin-right: 2em;
      font-size: x-large;
      cursor: pointer;
    }

    .mob {
      display: none;
    }

    .nav {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 2em;
      width: fit-content;
      margin: auto 0;

      .item {
        width: 100%;

        a {
          color: $pinklighter;
          text-decoration: none;
          text-align: center;
          display: block;
          width: 100%;
          font-size: smaller;
        }

        div {
          width: 0;
          margin-top: 5px;
          height: 3px;
          background-color: $pinklighter;
          transition: width 0.5s ease-in-out;
        }
      }

      .item:hover {
        a {
          color: $pinklight;
        }

        div {
          background-color: $pinklight;
          width: 100% !important;
        }
      }
    }

    button {
      background-color: $pink;
      border: none;
      border-radius: 5px;
      color: $primary;
      cursor: pointer;
      padding: 5px 1em;
      transition: background-color 0.5s ease-in-out;
    }

    button:hover {
      background-color: $primarydark;
      color: $pink;
    }
  }

  .headermodal {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    width: 85%;
    background-color: $primary;

    .hcontainer {
      padding: 1em 2em;
      position: relative;

      .ic {
        position: absolute;
        top: 1em;
        right: 1em;
        color: orange;
        font-size: x-large;
      }

      img {
        max-height: 54px;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
        display: block;
        margin: 2em auto;
      }

      h1 {
        color: $pinklighter;
        margin: 1em auto;
        font-size: x-large;

        text-align: center;
      }

      .nav {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        gap: 1em;
        width: 100%;
        margin: 1em auto;

        .item {
          width: 100%;
          padding: 10px;

          a {
            color: $pinklighter;
            text-decoration: none;
            text-align: center;
            display: block;
            width: 100%;
            font-size: large;
          }

          div {
            width: 0;
            margin-top: 5px;
            height: 3px;
            background-color: $pinklighter;
            transition: width 0.5s ease-in-out;
          }
        }

        .item:hover {
          a {
            color: $pinklight;
          }

          div {
            background-color: $pinklight;
            width: 100% !important;
          }
        }
      }

      button {
        background-color: $pink;
        border: none;
        border-radius: 5px;
        color: $primary;
        cursor: pointer;
        padding: 1em;
        width: 100%;
        font-size: medium;
        transition: background-color 0.5s ease-in-out;
      }

      button:hover {
        background-color: $primarydark;
        color: $pink;
      }
    }
  }

  @include maxwidth(mobile) {
    .container {
      grid-template-columns: auto 1fr auto;

      .nav,
      button {
        display: none;
      }

      .mob {
        display: block;
        margin: auto;
        color: $pinklighter;
      }
    }
  }

  @include maxwidth(tablet) {
    .container {
      grid-template-columns: auto 1fr auto;

      .nav,
      button {
        display: none;
      }

      .mob {
        display: block;
        margin: auto;
        color: $pinklighter;
      }
    }
  }
}

.footer {
  background-color: $primarydark;
  padding: 1em 0;

  .container {
    max-width: 85%;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 2em;

    .links {
      a {
        display: block;
        margin: 8px 0;
        text-decoration: none;
        color: $pinklighter;
        font-size: small;
      }

      a:hover {
        color: $pinklight;
      }
    }

    .center {
      width: fit-content;
      margin: auto;

      h1 {
        color: $pinklighter;
        margin-bottom: 10px;
        text-align: center;
      }

      p {
        color: $pinklighter;
        text-align: center;
        font-size: small;
      }
    }

    img {
      max-height: 54px;
      height: 100%;
      object-fit: contain;
      display: block;
      margin: auto 0 auto auto;
    }
  }

  @include maxwidth(mobile) {
    .container {
      grid-template-columns: 1fr;

      img {
        margin: auto;
        order: 0;
      }

      .center {
        order: 1;
      }
    }
  }

  @include maxwidth(tablet) {
    .container {
      grid-template-columns: 1fr;

      img {
        margin: auto;
        order: 0;
      }

      .center {
        order: 1;
      }
    }
  }
}

.title {
  margin: 0 auto 2em auto;
  width: fit-content;

  h2 {
    color: $primary;
  }

  div {
    background-color: $pink;
    height: 8px;
    margin-top: 8px;
    margin-left: -30%;
    width: 0%;
    border-radius: 5px;
    animation: widthAnimation 5s infinite ease-in-out;
  }

  @keyframes widthAnimation {
    0% {
      width: 0;
    }

    50% {
      width: 70%;
    }

    100% {
      width: 0;
    }
  }
}

.l-title {
  width: fit-content;
  margin: 0 auto 2em 0;

  h2 {
    color: $primary;
    padding-left: 5vw;
    width: 100%;
  }

  div {
    background-color: $pink;
    height: 8px;
    margin-top: 8px;
    width: 0%;
    border-radius: 5px;
    animation: widthAnimation 5s infinite ease-in-out;
  }

  @keyframes widthAnimation {
    0% {
      width: 0;
    }

    50% {
      width: 10vw;
    }

    100% {
      width: 0;
    }
  }
}

.cpagination {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin: 2em auto 10px auto;

  p {
    display: block;
    min-width: 24px;
    width: fit-content !important;
    margin: 0 10px 0 10px;
    font-weight: 400;
    background-color: $pink;
    text-align: center;
    height: 24px !important;
    line-height: 24px;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5 {
    display: block;
    min-width: 24px;
    padding: 0 4px;
    width: fit-content !important;
    margin: auto;
    font-weight: 400;
    background-color: $primary;
    text-align: center;
    line-height: 24px !important;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5:hover {
    background-color: $pink;
  }

  .active {
    background-color: $pink;
  }

  .fa-arrow-right,
  .fa-arrow-left {
    background-color: $pink;
    cursor: pointer !important;
    font-size: small;
    border-radius: 6px;
    color: white;
    width: 24px;
    text-align: center;
    height: 24px !important;
    line-height: 24px !important;
  }

  .fa-arrow-right:hover,
  .fa-arrow-left:hover {
    background-color: $pinklight;
  }
}

.div1auto {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1em;
}

.search {
  position: relative;
  height: fit-content;

  input {
    background-color: $pinklighter;
    color: $primarydark;
    border: 1px solid #60606050;
    padding: 8px;
    border-radius: 5px;
  }

  .ic {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    display: grid;
    place-content: center;

    * {
      color: $primary;
    }
  }
}

.input-container {
  position: relative;
  margin-bottom: 1em;
  width: 100%;

  .label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    background-color: $pinklighter;
    transition: top 0.3s, font-size 0.3s, color 0.3s;

    &.up {
      top: 0;
      font-size: x-small;
      color: $primary;
      padding: 1px 16px;
      background-color: $pinklighter;
      border-radius: 2px;
      border: 1px solid #ccc;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: $pinklighter;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: $pinklighter;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  &.focused {
    textarea,
    input {
      border-color: #007bff;
    }
  }
}

.select {
  position: relative;
  margin-bottom: 1em;
  width: 100%;

  .label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    background-color: $pinklighter;
    transition: top 0.3s, font-size 0.3s, color 0.3s;

    &.up {
      top: 0;
      font-size: x-small;
      color: $primary;
      padding: 1px 16px;
      background-color: $pinklighter;
      border-radius: 2px;
      border: 1px solid #ccc;
    }
  }

  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: $pinklighter;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  &.focused {
    select {
      border-color: #007bff;
    }
  }
}

.button {
  display: block;
  margin: auto;
  background-color: $primary;
  border: none;
  border-radius: 5px;
  color: $pinklighter;
  cursor: pointer;
  padding: 8px 5em;
  transition: background-color 0.5s ease-in-out;
}

.button:hover {
  background-color: $primarydark;
}

.ripple {
  display: grid;
  place-content: center;
  background-color: rgba($color: #000000, $alpha: 0.2);
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .loader {
    width: 80px;
    height: 40px;
    --c: no-repeat radial-gradient(farthest-side, orangered 93%, #0000);
    background: var(--c) 0 0, var(--c) 50% 0, var(--c) 100% 0;
    background-size: 16px 16px;
    position: relative;
    animation: l4-0 1s linear infinite alternate;
  }
}

.loader:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: orange;
  border-radius: 5px;
  left: 0;
  top: 0;
  animation: l4-1 1s linear infinite alternate,
    l4-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

.div2equal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.div3equal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

.div4equal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}

@keyframes l4-0 {
  0% {
    background-position: 0 100%, 50% 0, 100% 0;
  }

  8%,
  42% {
    background-position: 0 0, 50% 0, 100% 0;
  }

  50% {
    background-position: 0 0, 50% 100%, 100% 0;
  }

  58%,
  92% {
    background-position: 0 0, 50% 0, 100% 0;
  }

  100% {
    background-position: 0 0, 50% 0, 100% 100%;
  }
}

@keyframes l4-1 {
  100% {
    left: calc(100% - 8px);
  }
}

@keyframes l4-2 {
  100% {
    top: -0.1px;
  }
}

@include maxwidth(mobile) {
  .div2equal,
  .div1auto,
  .divauto,
  .divinput,
  .div3equal {
    grid-template-columns: 1fr !important;
    gap: 10px;
  }

  .div4equal {
    grid-template-columns: 1fr !important;
    gap: 10px;
  }
}

@include maxwidth(tablet) {
  .div2equal,
  .divauto,
  .divinput,
  .div1auto,
  .div3equal {
    grid-template-columns: 1fr !important;
  }

  .div4equal {
    grid-template-columns: 1fr 1fr !important;
  }
}
